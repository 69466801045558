import { BaseComponent } from "js/abstracts/baseComponent.js";
import { findReplayButton } from "js/components/forms/replayButton.js";

// Symbols representing different states of the CSRFForm component
const STATUS = Object.freeze({
    TOKEN_MISSING: Symbol("token missing"),
    FETCH_IN_PROGRESS: Symbol("fetch in progress"),
    TOKEN_FETCHED: Symbol("token fetched"),
    TOKEN_EXPIRED: Symbol("token expired")
});

/**
 * Component that requests a CSRF token from the server
 * and inserts it into the form just before submission.
 */
export class CSRFForm extends BaseComponent {
    // Symbol used to store the form state in the form element
    static formStatusSymbol = Symbol("CSRF");

    /**
     * Gets default options for the CSRFForm component.
     * @returns {Object} - Object with default options.
     */
    get Defaults() {
        return {
            url: "",
            fieldName: "csrfmiddlewaretoken"
        };
    }

    /**
     * Gets the current state of the CSRF form.
     * @returns {Symbol} - The CSRF form state.
     */
    get status() {
        return this.formElement[this.constructor.formStatusSymbol] || STATUS.TOKEN_MISSING;
    }

    /**
     * Sets the state of the CSRF form.
     * @param {Symbol} value - The new state of the form.
     */
    set status(value) {
        this.formElement[this.constructor.formStatusSymbol] = value;
    }

    /**
     * Gets the field name for the CSRF token from the data attribute or default options.
     * @returns {string} - The CSRF field name.
     */
    get fieldName() {
        return this.formElement.getAttribute("data-csrf-field-name") || this.options.fieldName;
    }

    /**
     * Constructor for the CSRFForm class.
     * @param {HTMLFormElement} formElement - The HTML form element.
     * @param {Object} options - Additional options for the form.
     */
    constructor(formElement, options) {
        super(options);
        this.formElement = formElement;

        // Event listener for the form's submit event
        this.on(
            this.formElement,
            "submit",
            async event => {
                console.debug("CSRF: Captured 'submit' event.", event.submitter.name);

                switch (this.status) {
                    case STATUS.FETCH_IN_PROGRESS:
                        // Some protection against duplicate events.
                        // This is the only status where the submit event can be filtered out.
                        console.debug("CSRF: Token now fetching. Current event will be aborted.");
                        event.preventDefault();
                        event.stopPropagation();
                        break;
                    case STATUS.TOKEN_FETCHED:
                        console.debug("CSRF: Token exists. Continue...");
                        break;
                    default:
                        console.debug("CSRF: Token is missing or expired. Current event will be aborted.");
                        event.preventDefault();
                        event.stopPropagation();

                        this.status = STATUS.FETCH_IN_PROGRESS;
                        console.debug("CSRF: Fetching a new token...");
                        const token = await this.fetchToken();
                        this.status = STATUS.TOKEN_FETCHED;
                        console.debug("CSRF: Token successfully fetched.");

                        this.setupField(token);
                        console.debug("CSRF: Token has been added. Resubmit the form...");

                        const replayButton = findReplayButton(this.formElement);
                        this.formElement.requestSubmit(replayButton);
                }
            },
            { capture: true }
        );

        // Event listener for the global submit event
        this.on(document, "submit", event => {
            if (event.target === this.formElement && this.status === STATUS.TOKEN_FETCHED) {
                queueMicrotask(() => {
                    this.invalidateToken();
                });
            }
        });
    }

    /**
     * Requests a new CSRF token from the server.
     * @returns {Promise<string>} - A promise that resolves to the new CSRF token.
     */
    async fetchToken() {
        const response = await fetch(this.options.url, {
            mode: "same-origin",
            referrerPolicy: "origin-when-cross-origin"
        });

        if (!response.ok) {
            throw new Error(`${response.status} ${response.statusText}`);
        }

        return response.headers.get("X-CSRF-Token");
    }

    /**
     * Sets the value of the CSRF field in the form.
     * @param {string} value - The CSRF token value.
     */
    setupField(value) {
        let field = this.formElement.querySelector(`input[name="${this.fieldName}"]`);
        if (!field) {
            field = document.createElement("input");
            field.type = "hidden";
            field.name = this.fieldName;
            this.formElement.prepend(field);
        }
        field.value = value;
    }

    /**
     * Removes the CSRF token field from the form.
     */
    removeField() {
        const field = this.formElement.querySelector(`input[name="${this.fieldName}"]`);
        if (field) {
            field.remove();
            this.status = STATUS.TOKEN_MISSING;
        }
    }

    /**
     * Invalidates the CSRF token.
     * Since CSRF tokens are one-time-use, it is necessary to invalidate
     * the token immediately after its use.
     */
    invalidateToken() {
        this.status = STATUS.TOKEN_EXPIRED;
        console.debug("CSRF: Token now marked as expired.");
    }
}
