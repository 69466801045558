import XClass from "data-xclass";

import "./footer-menu.pcss";

const footerMenu = document.querySelector(".footer-menu");
const mql = window.matchMedia("(min-width: 1024px)");
mql.addEventListener("change", onChangeWidth);
onChangeWidth(mql);

function onChangeWidth(event) {
    if (event.matches) {
        XClass.deleteWidget(footerMenu, "accordion");
    } else {
        XClass.addWidget(footerMenu, "accordion");
    }
}
