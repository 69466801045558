import { SVG } from "@svgdotjs/svg.js";

import "./flight-area.pcss";


export class FlightAnimation {
    constructor(root, startPoint) {
        this.root = root;
        this.startPoint = startPoint;

        this.pointAreas = SVG(this.root.querySelector(".flight-area__point-areas"));
        this.pathNode = SVG(this.root.querySelector(".flight-area__path"));
        this.image = SVG(this.root.querySelector(".flight-area__image"));
        this.randomPoints = null;
    }

    updatePath() {
        const startPoint = this.randomPoints ? this.randomPoints[this.randomPoints.length - 1] : this.startPoint;
        this.randomPoints = getRandomPath(this.pointAreas, startPoint);

        const pathString = buildPath(this.randomPoints);
        this.pathNode.attr("d", pathString);
    }

    start() {
        this.updatePath();

        const firstAnimation = this.image.node.querySelector("animate, animateMotion");
        firstAnimation.beginElement();
    }

    /**
     * Метод для синхронизации анимаций.
     */
    trigger() {
        this.updatePath();

        const lastAnimation = this.image.node.querySelector("animateMotion:last-child");
        if (lastAnimation) {
            lastAnimation.beginElement();
        }
    }
}


/**
 * Возвращает список случайных точек для пути.
 *
 * @param {Object} pointAreas
 * @param {Object<x: number, y: number>} [startPoint]
 * @return {Object<type: string, anchors:string, x: number, y: number>[]}
 */
function getRandomPath(pointAreas, startPoint) {
    if (!startPoint) {
        const coords = pointAreas.node.dataset.initialPoint.split(",").map(x => parseFloat(x.trim()));
        startPoint = {
            x: coords[0],
            y: coords[1],
        }
    }

    const randomPoints = [];
    randomPoints.push({
        type: "M",
        anchors: "",
        x: startPoint.x,
        y: startPoint.y,
    });

    for (let shapeNode of iterateGroup(pointAreas.node)) {
        const randomPoint = getRandomPoint(shapeNode);
        const pointType = shapeNode.dataset.type || "M";
        const anchors = shapeNode.dataset.anchors;

        randomPoints.push({
            type: pointType,
            anchors: anchors,
            x: randomPoint.x,
            y: randomPoint.y,
        })
    }

    return randomPoints;
}


/**
 *
 * @param {SVGGElement} group
 */
function iterateGroup(group) {
    function getRandomChild(group) {
        const childs = Array.from(group.children);
        const randomIndex = Math.floor(Math.random() * childs.length);
        return childs[randomIndex];
    }

    function* iterateChilds(group) {
        if (group.dataset.hasOwnProperty("selectRandom")) {
            const child = getRandomChild(group);
            if (child instanceof SVGGElement) {
                yield* iterateChilds(child);
            } else {
                yield child;
            }
        } else {
            const children = group.children;
            for (let i = 0; i < children.length; i++) {
                const child = children[i];
                if (child instanceof SVGGElement) {
                    yield* iterateChilds(child);
                } else {
                    yield child;
                }
            }
        }
    }

    return iterateChilds(group);
}


/**
 * Строит из массива точек строку для <path>.
 *
 * @param {Object<type: string, anchors:string, x: number, y: number>[]} points
 * @return {string}
 */
function buildPath(points) {
    let lastPoint;
    const pathItems = [];
    points.forEach(point => {
        lastPoint = point;

        switch (point.type) {
            case "M":
            case "T":
                pathItems.push(`${point.type}${point.x},${point.y}`);
                break
            case "S":
            case "Q":
            case "C":
                pathItems.push(`${point.type}${point.anchors} ${point.x},${point.y}`);
                break
        }
    });

    return pathItems.join(" ");
}


/**
 * Возвращает координаты случайной точки внутри SVG-фигуры.
 * Работает только для выпуклых фигур.
 *
 * @param {SVGGeometryElement} shape
 * @return {{x: number, y: number}}
 */
function getRandomPoint(shape) {
    const totalLength = shape.getTotalLength(),
        point_1 = shape.getPointAtLength(Math.random() * totalLength),
        point_2 = shape.getPointAtLength(Math.random() * totalLength),
        ratio = Math.random();

    // get the random point
    const x = point_1.x + (point_2.x - point_1.x) * ratio,
        y = point_1.y + (point_2.y - point_1.y) * ratio;

    return {x: Math.round(x), y: Math.round(y)};
}
