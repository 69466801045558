import { throttle } from "lodash-es";

import "./solutions-block.pcss";

let currentCardRect;

function initCards() {
    solutionsBlock.forEach(block => {
        block.addEventListener("mousemove", throttle(onMouseMove, 100));

        block.querySelectorAll(".solutions-block__card").forEach(card => {
            card.addEventListener("mouseenter", onMouseEnter);
            card.addEventListener("mouseleave", onMouseLeave);
        });
    });
}

function disableCards() {
    solutionsBlock.forEach(block => {
        block.removeEventListener("mousemove", onMouseMove);
        block.querySelectorAll(".solutions-block__card").forEach(card => {
            card.removeEventListener("mouseenter", onMouseEnter);
            card.removeEventListener("mouseleave", onMouseLeave);
        });
    });
}

function onMouseMove(event) {
    const card = event.target.closest(".solutions-block__card");
    if (!card) {
        return;
    }

    if (!currentCardRect) {
        return;
    }

    const x = event.clientX - currentCardRect.left;
    const y = event.clientY - currentCardRect.top;
    const xPrecentage = x / currentCardRect.width;
    const yPrecentage = y / currentCardRect.height;
    const xRotation = (xPrecentage - 0.5) * 10;
    const yRotation = (0.5 - yPrecentage) * 10;

    card.style.setProperty("--x-rotation", `${yRotation}deg`);
    card.style.setProperty("--y-rotation", `${xRotation}deg`);
    card.style.setProperty("--x-glance", `${(1 - xPrecentage) * 100}%`);
    card.style.setProperty("--y-glance", `${(1 - yPrecentage) * 100}%`);
}

function onMouseEnter(event) {
    currentCardRect = event.currentTarget.getBoundingClientRect();
}

function onMouseLeave(event) {
    event.currentTarget.style.setProperty("--x-rotation", `0deg`);
    event.currentTarget.style.setProperty("--y-rotation", `0deg`);
}

const solutionsBlock = document.querySelectorAll(".solutions-block");
if (solutionsBlock.length) {
    const mql = window.matchMedia("(min-width: 800px)");
    const onChange = event => {
        if (event.matches) {
            initCards();
        } else {
            disableCards();
        }
    };

    mql.addEventListener("change", onChange);
    onChange(mql);
}
