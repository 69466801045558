import "./tagline.pcss";

const taglineNodes = document.querySelectorAll(".tagline");
taglineNodes.forEach(block => {
    const swiperRoot = block.querySelector(".swiper");

    import(/* webpackChunkName: "swiper" */ "components/swiper/swiper.js").then(module => {
        new module.Swiper(swiperRoot, {
            spaceBetween: 25,
            slidesPerView: "auto",
            modules: [module.FreeMode],
            freeMode: true,
            breakpoints: {
                600: {
                    spaceBetween: 28
                },
                1024: {
                    spaceBetween: 34
                },
                1200: {
                    spaceBetween: 40
                }
            }
        });
    });
});
