import XClass from "data-xclass";
import { FormField } from "bem/common.fields/form-field/form-field.js";

import "css/vendor/choices.pcss";
import "./select-form-field.pcss";

/**
 * Класс для поля формы с чекбоксом.
 */
export class SelectField extends FormField {
    get Defaults() {
        return Object.assign(super.Defaults, {
            controlSelector: "select",
            choicesOptions: {
                shouldSort: false,
                shouldSortItems: false,
                resetScrollPosition: false,
                itemSelectText: "",
                searchResultLimit: 6,
                searchPlaceholderValue: "Search..."
            }
        });
    }

    constructor(element, options) {
        super(element, options);

        this.control = this.root.querySelector(this.options.controlSelector);

        // Атрибут required на скрытом <select> мешает отправлять форму
        // на валидацию.
        this._wasRequired = this.control.required;
        this.control.required = false;

        // Вероятно, баг в плагине Choices: если select изначально выключен,
        // то опции в плагине тоже будут выключены. И включить из обратно
        // в процессе выполнения будет уже невозможно. Поэтому, если <select>
        // имеет атрибут `disabled`, то мы его убираем, затем иниализируем плагин,
        // а потом выключаем селект методом самого плагина.
        const initiallyDisabled = this.control.disabled;
        this.control.disabled = false;
        this._choices = this._initChoices();
        if (initiallyDisabled) {
            this._choices.disable();
        }

        this._updateDisabledState();
        this._updateRequiredState();
    }

    destroy() {
        if (this._choices && this._choices instanceof window.Choices) {
            this._choices.destroy();
        }
        this.control.required = this._wasRequired;
        super.destroy();
    }

    get name() {
        return this.control.name;
    }

    get value() {
        return this.control.value;
    }

    get disabled() {
        return this.control.disabled;
    }

    set disabled(value) {
        if (value) {
            this._choices.disable();
        } else {
            this._choices.enable();
        }
    }

    get required() {
        return this.control.required;
    }

    set required(value) {
        this.control.required = value;
    }

    /**
     * Синхронизация с наличием/отсутствием атрибута disabled
     * на элементе <select>.
     * @private
     */
    _updateDisabledState() {
        this.root.classList.toggle(this.options.disabledClassName, this.disabled);

        // изменение состояния в плагине Choices
        if (this.disabled) {
            this._choices.disable();
        } else {
            this._choices.enable();
        }
    }

    /**
     * Синхронизация с наличием/отсутствием атрибута required
     * на элементе <select>.
     * @private
     */
    _updateRequiredState() {
        this.root.classList.toggle(this.options.requiredClassName, this.required);
    }

    /**
     * Инициализация плагина Choices.
     * @returns {Choices}
     * @private
     */
    _initChoices() {
        const hasPlaceholder = !!this.control.querySelector("option[value='']");
        const isMultiple = this.control.hasAttribute("multiple");
        const choicesOptions = Object.assign(
            {
                removeItemButton: isMultiple || hasPlaceholder,
                searchEnabled: Boolean(this.control.dataset.search) || this.control.dataset.search === ""
            },
            this.options.choicesOptions
        );

        return new window.Choices(this.control, choicesOptions);
    }
}

// ========================================================
//  Автоматическое создание экземпляров класса SelectField
// ========================================================

let libraryPromise = null;

XClass.register("select-field", {
    onRegister: function () {
        // наблюдатель за изменением атрибута disabled
        this.observer = new MutationObserver(mutationsList => {
            for (const mutation of mutationsList) {
                if (mutation.type === "attributes") {
                    const widgetRoot = XClass.findClosest(mutation.target, "select-field");
                    const fieldInstance = widgetRoot && FormField.getInstance(widgetRoot);
                    if (fieldInstance) {
                        switch (mutation.attributeName) {
                            case "disabled":
                                fieldInstance._updateDisabledState();
                                break;
                            case "required":
                                fieldInstance._updateRequiredState();
                                break;
                        }
                    }
                }
            }
        });
    },

    init: async function (element) {
        if (libraryPromise === null) {
            libraryPromise = await import(/* webpackChunkName: "choices" */ "js/vendor/choices.js");
        }

        // Проверяем, что элемент всё ещё находится в DOM
        if (!document.contains(element)) {
            return;
        }

        const instance = new SelectField(element, {
            controlSelector: ".select-form-field__control",
            choicesOptions: {
                callbackOnInit: () => {
                    const choicesRoot = element.querySelector(".choices");
                    if (choicesRoot && choicesRoot.dataset.type === "select-one") {
                        const div = document.createElement("DIV");
                        div.innerHTML = `
                        <svg class="choices__arrow" width="20" height="20">
                          <use href="#dropdown-arrow"></use>
                        </svg>`.trim();
                        choicesRoot.append(div.firstChild);
                    }
                }
            }
        });

        // добавление <select> элемента в перечень отслеживания
        // изменений атрибутов disabled и required.
        this.observer.observe(instance.control, {
            attributes: true,
            attributeFilter: ["disabled", "required"]
        });
    },

    destroy: function (element) {
        const fieldInstance = FormField.getInstance(element);
        if (fieldInstance) {
            fieldInstance.destroy();
        }
    }
});
