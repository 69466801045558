import Cookies from "js-cookie";
import XClass from "data-xclass";

//=================
//  CSS Reset
//=================
import "normalize.css/normalize.css";
import "css/utilities/reset.pcss";
import "css/utilities/list-unstyled.pcss";

//=================
//  Components
//=================

import "components/accordion/accordion.js";
import "components/editor/editor.js";
import "components/iframe/iframe.js";

import "js/components/forms/csrf.js";
import "js/components/forms/reCaptcha.js";
import "js/components/forms/utm.js";
import "js/components/in-view.js";

import "css/components/forms/errors.pcss";

// Swiper
import "swiper/css/bundle";
import "components/swiper/swiper.pcss";

//=================
//  BEM
//=================

// Tools
import "bem/common.blocks/chart-bg/chart-bg.js";
import "bem/common.blocks/container/container.js";
import "bem/common.blocks/gradient-text/gradient-text.js";
import "bem/common.blocks/header-placeholder/header-placeholder.js";
import "bem/common.blocks/image-card/image-card.js";
import "bem/common.blocks/infinite-scroll/infinite-scroll.js";
import "bem/common.blocks/lazy-box/lazy-box.js";

// Atomic blocks
import "bem/common.blocks/advantages-list/advantages-list.js";
import "bem/common.blocks/animated-link/animated-link.js";
import "bem/common.blocks/article-footer/article-footer.js";
import "bem/common.blocks/article-list/article-list.js";
import "bem/common.blocks/bordered-image/bordered-image.js";
import "bem/common.blocks/breadcrumbs/breadcrumbs.js";
import "bem/common.blocks/button/button.js";
import "bem/common.blocks/calculator-chart/calculator-chart.js";
import "bem/common.blocks/case-study/case-study.js";
import "bem/common.blocks/checkbox/checkbox.js";
import "bem/common.blocks/contact-line/contact-line.js";
import "bem/common.blocks/error-list/error-list.js";
import "bem/common.blocks/feature/feature.js";
import "bem/common.blocks/flight-area/flight-area.js";
import "bem/common.blocks/footer-menu/footer-menu.js";
import "bem/common.blocks/get-started-block/get-started-block.js";
import "bem/common.blocks/grecaptcha-hint/grecaptcha-hint.js";
import "bem/common.blocks/hamburger/hamburger.js";
import "bem/common.blocks/header-button/header-button.js";
import "bem/common.blocks/heading/heading.js";
import "bem/common.blocks/hero-features-list/hero-features-list.js";
import "bem/common.blocks/home-slider/home-slider.js";
import "bem/common.blocks/investments/investments.js";
import "bem/common.blocks/investment-approach-block/investment-approach-block.js";
import "bem/common.blocks/investment-block/investment-block.js";
import "bem/common.blocks/key-value-table/key-value-table.js";
import "bem/common.blocks/leadership-block/leadership-block.js";
import "bem/common.blocks/main-menu/main-menu.js";
import "bem/common.blocks/mobile-menu/mobile-menu.js";
import "bem/common.blocks/newsroom-panel/newsroom-panel.js";
import "bem/common.blocks/objective-block/objective-block.js";
import "bem/common.blocks/page/page.js";
import "bem/common.blocks/popup-button/popup-button.js";
import "bem/common.blocks/portfolio-block/portfolio-block.js";
import "bem/common.blocks/preloader/preloader.js";
import "bem/common.blocks/question/question.js";
import "bem/common.blocks/quote-block/quote-block.js";
import "bem/common.blocks/radiobox/radiobox.js";
import "bem/common.blocks/round-list/round-list.js";
import "bem/common.blocks/share/share.js";
import "bem/common.blocks/social-media/social-media.js";
import "bem/common.blocks/solutions-block/solutions-block.js";
import "bem/common.blocks/solution-examples/solution-examples.js";
import "bem/common.blocks/tagline/tagline.js";
import "bem/common.blocks/testimonial/testimonial.js";

// Form fields
import "bem/common.fields/checkbox-form-field/checkbox-form-field.js";
import "bem/common.fields/checkbox-option/checkbox-option.js";
import "bem/common.fields/checkbox-select-form-field/checkbox-select-form-field.js";
import "bem/common.fields/radiobox-option/radiobox-option.js";
import "bem/common.fields/radiobox-select-form-field/radiobox-select-form-field.js";
import "bem/common.fields/recaptcha-form-field/recaptcha-form-field.js";
import "bem/common.fields/select-form-field/select-form-field.js";
import "bem/common.fields/text-form-field/text-form-field.js";
import "bem/common.fields/textarea-form-field/textarea-form-field.js";

// Composite blocks
import "bem/common.blocks/animated-chart-block/animated-chart-block.js";
import "bem/common.blocks/article-topic/article-topic.js";
import "bem/common.blocks/begin-investments/begin-investments.js";
import "bem/common.blocks/calculator-block/calculator-block.js";
import "bem/common.blocks/calculator-interface/calculator-interface.js";
import "bem/common.blocks/contact-block/contact-block.js";
import "bem/common.blocks/contact-button-block/contact-button-block.js";
import "bem/common.blocks/contact-info-block/contact-info-block.js";
import "bem/common.blocks/features-block/features-block.js";
import "bem/common.blocks/footer/footer.js";
import "bem/common.blocks/header/header.js";
import "bem/common.blocks/hero/hero.js";
import "bem/common.blocks/service/service.js";
import "bem/common.blocks/share-block/share-block.js";
import "bem/common.blocks/team-popup/team-popup.js";
import "bem/common.blocks/testimonials-block/testimonials-block.js";
import "bem/common.blocks/side-panel/side-panel.js";

// Forms
import "bem/common.forms/contact-form/contact-form.js";
import "bem/common.forms/contact-block-form/contact-block-form.js";
import "bem/common.forms/get-started-form/get-started-form.js";

//=================
//  CSS utilities
//=================

import "css/utilities/bg-gray.pcss";
import "css/utilities/overflow-hidden.pcss";
import "css/utilities/spaces.pcss";
import "css/utilities/visually-hidden.pcss";

//=================
//  Application
//=================

// Styles
import "css/app.pcss";

// Pages
import "bem/common.pages/about-page/about-page.js";
import "bem/common.pages/blog-article-page/blog-article-page.js";
import "bem/common.pages/contacts-thank-you-page/contacts-thank-you-page.js";
import "bem/common.pages/investments-page/investments-page.js";
import "bem/common.pages/error-page/error-page.js";

window.addEventListener("DOMContentLoaded", () => {
    XClass.start();
});

// Включение плавного скролла страницы, но так, чтобы он
// не срабатывал при загрузке страницы.
window.addEventListener("load", () => {
    document.documentElement.style.scrollBehavior = "smooth";
});

export default {
    Cookies,
    XClass
};
