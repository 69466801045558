import "./service.pcss";

const services = document.querySelectorAll(".service");
services.forEach(block => {
    const swiperRoot = block.querySelector(".swiper");
    if (swiperRoot) {
        import(/* webpackChunkName: "swiper" */ "components/swiper/swiper.js").then(module => {
            new module.Swiper(swiperRoot, {
                speed: 1000,
                modules: [module.Pagination, module.Autoplay],
                pagination: {
                    dynamicBullets: true,
                    el: block.querySelector(".swiper-pagination")
                },
                autoplay: {
                    delay: 3000
                },
                breakpoints: {
                    0: {
                        spaceBetween: 10,
                        slidesPerView: 1,
                        centeredSlides: true
                    },
                    500: {
                        spaceBetween: 20,
                        slidesPerView: 2,
                        centeredSlides: false
                    },
                    800: {
                        spaceBetween: 20,
                        slidesPerView: 3,
                        centeredSlides: false
                    }
                }
            });
        });
    }
});
