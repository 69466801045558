import { SidePanel } from "./classes.js";

import "./side-panel.pcss";

const sidePanelElement = document.querySelector(".side-panel");
if (sidePanelElement) {
    new SidePanel(sidePanelElement, {
        breakpoint: 940,
        closeOnEscape: true,
        transitioningClassName: "side-panel--transitioning",
        transitioningOpenClassName: "side-panel--transitioning-open",
        transitioningCloseClassName: "side-panel--transitioning-close",
        openedClassName: "side-panel--opened",
        globalOpenedClassName: "overflow-hidden",
        open: function () {
            window.scrollTo(0, 0);
        }
    });
}
