import "./testimonials-block.pcss";


const testimonialsBlocks = document.querySelectorAll(".testimonials-block");
testimonialsBlocks.forEach(block => {
    const swiperRoot = block.querySelector(".swiper");

    import(/* webpackChunkName: "swiper" */ "components/swiper/swiper.js").then(module => {
        new module.Swiper(swiperRoot, {
            speed: 1000,
            spaceBetween: 20,
            slidesPerView: "auto",
            modules: [module.Pagination, module.Autoplay],
            autoplay: {
                delay: 3000
            },
            pagination: {
                dynamicBullets: true,
                el: block.querySelector(".swiper-pagination")
            }
        });
    })
});
