import { throttle } from "lodash-es";

import "./newsroom-panel.pcss";


const topicButton = document.querySelector(".newsroom-panel__topic-button");
const tagWindow = document.querySelector(".newsroom-panel__tag-window");
const backdrop = document.querySelector(".newsroom-backdrop");
if (topicButton && tagWindow && backdrop) {
    topicButton.addEventListener("click", () => {
        toggleTopicWindow();
    });

    tagWindow.addEventListener("transitionend", event => {
        const isActive = tagWindow.classList.contains("newsroom-panel__tag-window--visible");
        if (!isActive) {
            popHeroDown();
        }
    });

    backdrop.addEventListener("click", () => {
        toggleTopicWindow();
    });

    updateWindowMaxHeight();
    window.addEventListener("resize", throttle(updateWindowMaxHeight, 250));
}


function toggleTopicWindow() {
    const isActive = tagWindow.classList.contains("newsroom-panel__tag-window--visible");
    if (!isActive) {
        popHeroUp();
    }

    topicButton.classList.toggle("newsroom-panel__topic-button--active");
    tagWindow.classList.toggle("newsroom-panel__tag-window--visible");
    backdrop.classList.toggle("newsroom-backdrop--visible");
}


function popHeroUp() {
    const hero = document.querySelector(".hero");
    hero && (hero.style.zIndex = "101");  // больше, чем у .newsroom-backdrop
}

function popHeroDown() {
    const hero = document.querySelector(".hero");
    hero && (hero.style.zIndex = "");
}


function updateWindowMaxHeight() {
    tagWindow.style.setProperty("--max-height", tagWindow.scrollHeight + "px");
}
