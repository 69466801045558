import { SVG } from "@svgdotjs/svg.js";

import "./calculator-chart.pcss";


/**
 * Класс калькулятора.
 */
class Calculator {
    constructor(chart) {
        this.chart = chart;
        this.chartWidth = 220;
        this.chartHeight = 210;

        // Cмещение области графика относительно области рисования.
        this.chartXOffset = 78.5;
        this.chartYOffset = 1;

        this.initialInvestmentField = document.getElementById("calculator-initial-investment");
        this.investmentTimeField = document.getElementById("calculator-investment-time");
        this.typeField = document.getElementById("calculator-investment-type");
        this.result = document.querySelector(".calculator-block__result");

        this.draw();
        this.addEventListeners();
    }

    /**
     * Начальная сумма инвестирования.
     *
     * @return {number}
     */
    get initialInvestment() {
        return this.initialInvestmentField.valueAsNumber;
    }

    /**
     * Время инвестирования в количестве 3-х месячных интервалах.
     *
     * @return {number}
     */
    get investmentTime() {
        return this.investmentTimeField.valueAsNumber;
    }

    /**
     * Стратегия инвестирования.
     *
     * @return {string}
     */
    get type() {
        return this.typeField.querySelector("input:checked").value;
    }

    /**
     * Общая сумма к концу инвестиционного периода.
     *
     * @param {number} investmentTime
     * @return {number}
     */
    getRefund(investmentTime) {
        if (this.type === "cash") {
            return Math.round(this.initialInvestment * (1 + investmentTime * 0.015));
        } else {
            return Math.round(this.initialInvestment * (1.015 ** investmentTime));
        }
    }

    addEventListeners() {
        this.initialInvestmentField.addEventListener("input", () => {
            this.draw();
        });

        this.investmentTimeField.addEventListener("input", () => {
            this.draw();
        });

        this.typeField.addEventListener("input", () => {
            this.draw();
        });
    }

    draw() {
        const refund = this.getRefund(this.investmentTime);
        this.result.firstElementChild.textContent = formatCurrency(refund.toString(), true);

        this._prepare();
        this._drawXAxis();
        this._drawYAxis();
        this._drawChart();
    }

    _prepare() {
        // Минимальное значение на оси X.
        const currentDate = (new Date()).getFullYear() + ((new Date()).getMonth() + 1) / 12;
        this._xMin = Math.ceil(currentDate * 4) / 4;

        // Ширина 3-х месячного интервала инвестирования в SVG-единицах.
        this._xSectionWidth = this.chartWidth / this.investmentTime;

        // Финальная сумма инвестиций
        const yMaxValue = this.initialInvestment * 2;

        // Величина интервала в долларах для оси Y.
        this._ySectionAmount = this._chooseYInterval(yMaxValue);

        // Количество секций по оси Y.
        this._ySectionСount = Math.ceil(yMaxValue / this._ySectionAmount);

        // Высота одной секции по оси Y в SVG-единицах.
        this._ySectionHeight = this.chartHeight / this._ySectionСount;

        // Координаты точек инвестирования.
        this._investmentPoints = [];
        for (let i = 0; i <= this.investmentTime; i++) {
            this._investmentPoints.push({
                value: i,
                refund: this.getRefund(i),
                year: this._xMin + (1 / 4) * i,
                x: i * this._xSectionWidth,
                y: this.chartHeight - (this.getRefund(i) / this._ySectionAmount) * this._ySectionHeight
            });
        }
    }

    /**
     * Выбор величины секции по оси Y в долларах.
     *
     * @param {number} refund
     * @return {number}
     * @private
     */
    _chooseYInterval(refund) {
        if (refund <= 200000) {
            return 25000;
        }

        const minAmount = Math.ceil(refund / 50000 / 6) * 50000;
        const maxAmount = Math.ceil(refund / 50000 / 3) * 50000;

        const availableAmounts = [];
        for (let k = minAmount; k <= maxAmount; k += 50000) {
            availableAmounts.push(k);

            // Отдаём приоритет значению, которому кратно начльное инвестирование.
            if (this.initialInvestment % k === 0) {
                return k;
            }
        }

        return availableAmounts[0];
    }

    _drawXAxis() {
        const lines = SVG(this.chart.querySelector(".calculator-chart__x-axis-lines"));
        lines.clear();

        const labels = SVG(this.chart.querySelector(".calculator-chart__x-axis"));
        labels.clear();

        let previousLabelX = -8;
        for (let i = 1; i <= this._investmentPoints.length; i++) {
            const investmentPoint = this._investmentPoints[i - 1];

            if (Number.isInteger(investmentPoint.year)) {
                // Рисование вертикальной линии для каждого целочисленного года.
                if (investmentPoint.x < this.chartWidth) {
                    lines.line(investmentPoint.x, 0, investmentPoint.x, this.chartHeight);
                }

                // Добавление текстовой метки.
                const label = labels.text(`${investmentPoint.year}`)
                .move(investmentPoint.x, 0);

                if (investmentPoint.x >= this.chartWidth - 11) {
                    label.font({ anchor: "end" });
                } else {
                    label.font({ anchor: "middle" });
                }

                const labelBox = label.bbox();
                if ((labelBox.x - previousLabelX) >= 8) {
                    previousLabelX = labelBox.x2;
                } else {
                    label.remove();
                }
            }
        }
    }

    _drawYAxis() {
        const axis = SVG(this.chart.querySelector(".calculator-chart__y-axis"));
        axis.clear();

        for (let i = 1; i <= this._ySectionСount; i++) {
            const labelY = this.chartHeight - i * this._ySectionHeight;

            axis.text(`${formatCurrency(i * this._ySectionAmount, true)}`)
            .move(70, labelY)
            .font({ anchor: "end" });

            // axis.line(0, labelY, 300, labelY)
            // .stroke({
            //     color: "#ffff00",
            //     width: 1
            // });
        }
    }

    _drawChart() {
        const chart = SVG(this.chart.querySelector(".calculator-chart__chart"));
        chart.clear();

        const linePoints = [];
        for (let i = 1; i <= this._investmentPoints.length; i++) {
            const investmentPoint = this._investmentPoints[i - 1];
            linePoints.push([investmentPoint.x, investmentPoint.y]);
        }

        chart.polyline(linePoints)
        .attr({
            class: "calculator-chart__line",
            pathLength: 1
        });

        chart
        .polygon(
            linePoints.concat([
                [this.chartWidth, this.chartHeight],
                [0, this.chartHeight]
            ])
        )
        .attr({
            "class": "calculator-chart__line-shadow",
            "clip-path": "url(#calculatorPolygonClip)"
        });
    }
}


const calculatorChart = document.querySelectorAll(".calculator-chart");
calculatorChart.forEach(chart => {
    new Calculator(chart);
})


/**
 * @param {string | number} value
 * @param {boolean} [comma]
 * @return {string}
 */
function formatCurrency(value, comma = false) {
    return `$ ${value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, comma ? "," : " ")}`;
}
