/* global ngettext */

import "./calculator-interface.pcss";


const rangeSliders = document.querySelectorAll(".calculator-interface__range-slider");
rangeSliders.forEach(field => {
    const input = field.querySelector("input[type=range]");
    const valueField = document.getElementById(input.dataset.valueId);

    const onInput = function(input) {
        const progress = (input.value / input.max) * 100;
        input.style.backgroundImage = `linear-gradient(to right, #d49f7a 0, #a6613a ${progress}%, transparent ${progress}%)`;

        if (input.name === "initial-investment") {
            valueField.textContent = formatCurrency(input.value, true);
        } else if (input.name === "investment-time") {
            valueField.textContent = formatTime(input.valueAsNumber * 3);
        } else {
            valueField.textContent = input.value;
        }
    }

    input.addEventListener("input", event => {
        onInput(event.target);
    });

    onInput(input);
});


/**
 * @param {string | number} value
 * @param {boolean} [comma]
 * @return {string}
 */
function formatCurrency(value, comma = false) {
    return `$ ${value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, comma ? "," : " ")}`;
}

/**
 * @param {number} value
 * @return {string}
 */
function formatTime(value) {
    const years = Math.floor(value / 12);
    const months = value % 12;

    const yearString = ngettext("%d Year", "%d Years", years);
    const monthString = ngettext("%d Month", "%d Months", months);

    const resultArray = [];
    if (years > 0) {
        resultArray.push(yearString.replace("%d", years));
    }
    if (months > 0) {
        resultArray.push(monthString.replace("%d", months));
    }

    return resultArray.join(" ");
}
