import Mustache from "mustache";
import { Popup } from "components/popup/popup.js";

import "./leadership-block.pcss";

const leadershipBlocks = document.querySelectorAll(".leadership-block");
leadershipBlocks.forEach(block => {
    block.addEventListener("click", event => {
        const button = event.target.closest("[data-team-popup]");
        if (!button) {
            return;
        }

        const teamMemberPk = button.dataset.teamPopup;
        const popupData = window.teamPopupData[teamMemberPk];
        if (!popupData) {
            return;
        }

        const templateNode = document.getElementById("team-member-popup");
        const template = templateNode.innerHTML;
        const popupContent = Mustache.render(template, popupData).trim();

        const popup = new Popup({
            classes: "team-popup",
            content: popupContent,
            closed: function () {
                this.destroy();
            }
        });
        popup.open();
    });
});
