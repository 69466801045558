/*
 * Добавление на форму скрытой submit-кнопки с именем "replay",
 * чтобы использовать её в методе requestSubmit() и отличать событие,
 * инициированное пользователем, от событий, инициированных скриптом.
 */

import XClass from "data-xclass";

/**
 * Поиск скрытой кнопки на форме.
 * @param {HTMLElement} formElement
 * @returns {HTMLElement|null}
 */
export function findReplayButton(formElement) {
    return formElement.querySelector("button[name='replay']");
}

/**
 * Проверка, что событие submit было инициализировано скрытой кнопкой.
 * @param {SubmitEvent} event
 * @returns {boolean}
 */
export function isReplayEvent(event) {
    return event.submitter.name === "replay";
}

XClass.register("replay-button", {
    init: function (element, self) {
        let hiddenButton = findReplayButton(element);
        if (!hiddenButton) {
            hiddenButton = document.createElement("button");
            hiddenButton.type = "submit";
            hiddenButton.name = "replay";
            hiddenButton.hidden = true;
            XClass.mutateDOM(() => {
                element.prepend(hiddenButton);
            });
        }
    },
    destroy: function (element, self) {
        const hiddenButton = findReplayButton(element);
        if (hiddenButton) {
            XClass.mutateDOM(() => {
                hiddenButton.remove();
            });
        }
    }
});
