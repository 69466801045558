/* global grecaptcha */

import { BaseComponent } from "js/abstracts/baseComponent.js";
import { findReplayButton } from "js/components/forms/replayButton.js";
import { getGlobalSiteKey, getScriptInfo, importScript, registerScript, STATUS } from "./library.js";

// Token status for reCAPTCHA v3.
const TOKEN_STATUS = Object.freeze({
    MISSING: Symbol("token missing"),
    FETCH_IN_PROGRESS: Symbol("fetch in progress"),
    FETCHED: Symbol("token fetched"),
    EXPIRED: Symbol("token expired")
});

// The reCAPTCHA v3 token is valid for 2 minutes.
// Set the expiration time slightly less than that.
const TOKEN_EXPIRATION_TIME = 100000;

/**
 * Component for obtaining the Google reCAPTCHA v3 token for the form.
 */
export class reCaptchaV3Form extends BaseComponent {
    get Defaults() {
        return {
            fieldName: "captcha"
        };
    }

    /**
     * Gets the field name for the reCAPTCHA token in the form.
     * @returns {string|*|string|string}
     */
    get fieldName() {
        return this.formElement.getAttribute("data-recaptcha-field-name") || this.options.fieldName;
    }

    /**
     * Gets the current reCAPTCHA token obtained for the form.
     * @returns {string}
     */
    get token() {
        return this._token;
    }

    /**
     * Sets the reCAPTCHA token. Also updates the timestamp for the token.
     * @param {string} value
     */
    set token(value) {
        this._token = value;
        this._tokenTime = new Date();
    }

    /**
     * @param {HTMLFormElement} formElement
     * @param {Object} [options]
     */
    constructor(formElement, options) {
        super(options);
        this.formElement = formElement;

        this.status = TOKEN_STATUS.MISSING;
        this.token = "";

        this._fetchTokenPromise = null;

        this.on(
            this.formElement,
            "submit",
            async event => {
                console.debug("reCAPTCHA: Captured 'submit' event.");

                switch (this.status) {
                    case TOKEN_STATUS.FETCH_IN_PROGRESS:
                        // A slight protection against repeated events.
                        // This is the only status for which
                        // the submit event can be filtered out.
                        console.debug("reCAPTCHA: Token now fetching. Current event will be aborted.");
                        event.preventDefault();
                        event.stopPropagation();
                        break;
                    case TOKEN_STATUS.FETCHED:
                        if (this.isValidToken()) {
                            console.debug("reCAPTCHA: Token exists. Continue...");
                            break;
                        }
                    // fallthrough
                    default:
                        console.debug("reCAPTCHA: Token is missing or expired. Current event will be aborted.");
                        event.preventDefault();
                        event.stopPropagation();

                        const token = await this.fetchToken();
                        this.setupField(token);
                        console.debug("reCAPTCHA: Token has been added. Resubmit the form...");

                        const replayButton = findReplayButton(this.formElement);
                        this.formElement.requestSubmit(replayButton);
                }
            },
            { capture: true }
        );

        this.on(document, "submit", event => {
            if (event.target === this.formElement && this.status === TOKEN_STATUS.FETCHED) {
                queueMicrotask(() => {
                    this.invalidateToken();
                });
            }
        });

        this._prefetch("preconnect", "https://www.google.com");
        this._prefetch("preconnect", "https://www.gstatic.com");
        this._prefetch("preconnect", "https://fonts.gstatic.com");
    }

    /**
     * Find the DOM element of the reCAPTCHA field.
     * @returns {HTMLInputElement}
     */
    getField() {
        return this.formElement.querySelector(`input[name="${this.fieldName}"]`);
    }

    /**
     * Set the value for the reCAPTCHA field.
     * If the field does not exist, it will be created and added to the form.
     * @param {string} value
     */
    setupField(value) {
        let field = this.getField();
        if (!field) {
            field = document.createElement("input");
            field.type = "hidden";
            field.name = this.fieldName;
            this.formElement.prepend(field);
        }
        field.value = value;
    }

    /**
     * Remove the reCAPTCHA field from the form.
     */
    removeField() {
        const field = this.getField();
        field && field.remove();
    }

    /**
     * Get the public key for reCAPTCHA.
     * @returns {string}
     */
    getSiteKey() {
        const field = this.getField();
        return (field && field.dataset.sitekey) || getGlobalSiteKey();
    }

    /**
     * Get the reCAPTCHA v3 action.
     * @returns {string}
     */
    getAction() {
        const field = this.getField();
        return (field && field.dataset.action) || "form";
    }

    /**
     * Check if the current token is valid.
     * @returns {boolean}
     */
    isValidToken() {
        return (
            this.status === TOKEN_STATUS.FETCHED && this.token && new Date() - this._tokenTime <= TOKEN_EXPIRATION_TIME
        );
    }

    /**
     * Obtain the reCAPTCHA token.
     * The token's lifetime is two minutes. If the token exists and is fresh, it is returned.
     * If it is expired, it is refreshed.
     * @param {boolean} forceUpdate
     * @returns {Promise<string>}
     */
    async fetchToken(forceUpdate = false) {
        const siteKey = this.getSiteKey();

        let scriptInfo = getScriptInfo(siteKey);
        if (!scriptInfo) {
            scriptInfo = registerScript(siteKey);
        }

        switch (scriptInfo.status) {
            case STATUS.MISSING:
                await importScript(siteKey);
                break;
            case STATUS.LOADING:
                await scriptInfo.loadingPromise;
                break;
        }

        return (this._fetchTokenPromise = new Promise(resolve => {
            switch (this.status) {
                case TOKEN_STATUS.FETCH_IN_PROGRESS:
                    return this._fetchTokenPromise;
                case TOKEN_STATUS.FETCHED:
                    if (!forceUpdate && this.isValidToken()) {
                        queueMicrotask(() => {
                            resolve(this.token);
                        });
                        return;
                    }
                // fallthrough
                case TOKEN_STATUS.MISSING:
                case TOKEN_STATUS.EXPIRED:
                    this.status = TOKEN_STATUS.FETCH_IN_PROGRESS;
                    console.debug("reCAPTCHA: Fetching a new token...");

                    grecaptcha.ready(() => {
                        grecaptcha.execute(siteKey, { action: this.getAction() }).then(fetchedToken => {
                            this.token = fetchedToken;

                            this.status = TOKEN_STATUS.FETCHED;
                            console.debug("reCAPTCHA: Token successfully fetched.");

                            resolve(this.token);
                        });
                    });
                    break;
            }
        }));
    }

    /**
     * Invalidate the reCAPTCHA token.
     * Since reCAPTCHA tokens are one-time use, it is necessary to invalidate
     * the token immediately after use.
     */
    invalidateToken() {
        this.status = TOKEN_STATUS.EXPIRED;
        console.debug("reCAPTCHA: Token now marked as expired.");
    }

    _prefetch(rel, href, as) {
        const linkExists = document.head.querySelector(`link[href="${href}"]`);
        if (linkExists) {
            return
        }

        const linkEl = document.createElement("link");
        linkEl.rel = rel;
        linkEl.href = href;
        if (as) {
            linkEl.as = as;
        }
        document.head.append(linkEl);
    }
}
