import { BaseComponent } from "js/abstracts/baseComponent.js";
import { FlightAnimation } from "bem/common.blocks/flight-area/flight-area.js";

import "./home-slider.pcss";


class HomeSlider extends BaseComponent {
    constructor(root, options) {
        super(options);

        this.root = root;
        this.slides = Array.from(document.querySelectorAll(".home-slider__slide"));
        this.currentSlideIndex = 0;
        this.currentAnimation = null;

        this.root.classList.add("home-slider--visible");
        this._startOnLoad();
    }

    _startOnLoad() {
        const firstSlideImage = this.root.querySelector(".home-slider__image");
        let imagePromise;

        if (!firstSlideImage.complete || firstSlideImage.naturalWidth <= 1) {
            imagePromise = new Promise(resolve => {
                firstSlideImage.addEventListener("load", () => {
                    resolve();
                });
            });
        } else {
            imagePromise = Promise.resolve();
        }

        imagePromise.then(() => {
            setTimeout(() => {
                this.start();
            }, 500);
        });
    }

    start() {
        this.root.classList.add("home-slider--ready");

        this.root.querySelectorAll(".home-slider__image").forEach((image, index) => {
            if (index) { // skip first image
                window.lazySizes.loader.unveil(image);
            }
        });

        setTimeout(() => {
            this._animateIcons();

            setInterval(() => {
                this._animateNext();
            }, 3000);
        }, 2000);
    }

    _animateNext() {
        if (this.currentAnimation) {
            this.currentAnimation.abort();
        }

        setTimeout(() => {
            this._iconAnimations.forEach(animation => {
                animation.trigger();
            })
        }, 1000);

        const currentSlide = this.slides[this.currentSlideIndex];
        this.currentSlideIndex = (this.currentSlideIndex + 1) % this.slides.length;
        const nextSlide = this.slides[this.currentSlideIndex];

        this.currentAnimation = new SliderAnimation(currentSlide, nextSlide);
        this.currentAnimation.start();
    }

    _animateIcons() {
        const flightAreas = this.root.querySelectorAll(".flight-area");
        this._iconAnimations = [];

        flightAreas.forEach(area => {
            const animation = new FlightAnimation(area);
            animation.start();
            this._iconAnimations.push(animation);

            // Функционал для упрощения разработки.
            const points = [];
            area.addEventListener("click", event => {
                const clickX = event.clientX - area.getBoundingClientRect().left;
                const clickY = event.clientY - area.getBoundingClientRect().top;

                // Выводим координаты в консоль
                points.push(`${clickX.toFixed(0)},${clickY.toFixed(0)}`);
                console.log(points.join(" "));
            });
        });
    }
}


/**
 * Анимация смены слайдов.
 */
class SliderAnimation {
    constructor(slideA, slideB) {
        this.slideA = slideA;
        this.slideB = slideB;

        this.fadeDuration = 400;
        this.delayDuration = 100; // задержка в состоянии, когда скрыты оба слайда
    }

    start() {
        this._hideSlideA();

        this._delayTimeout = setTimeout(() => {
            this._showSlideB();
        }, this.fadeDuration + this.delayDuration);

        this._finishTimeout = setTimeout(
            () => {
                this._finish();
            },
            this.fadeDuration * 2 + this.delayDuration
        );
    }

    _hideSlideA() {
        this._animationA = this.slideA.animate(
            [
                {
                    opacity: 0
                }
            ],
            {
                duration: this.fadeDuration,
                fill: "forwards",
                easing: "ease-in"
            }
        );
    }

    _showSlideB() {
        this.slideB.style.display = "block";
        this.slideB.style.position = "absolute";
        this.slideB.style.top = "0";
        this.slideB.style.left = "0";
        this.slideB.style.width = "100%";
        this.slideB.style.opacity = "0";
        this.slideB.style.zIndex = "1";

        this._animationB = this.slideB.animate(
            [
                {
                    opacity: 1
                }
            ],
            {
                duration: this.fadeDuration,
                fill: "forwards",
                easing: "ease-in",
                composite: "add"
            }
        );
    }

    _finish() {
        if (this._animationA) {
            this._animationA.cancel();
            this._animationA = null;
        }
        this.slideA.style.display = "none";

        if (this._animationB) {
            this._animationB.cancel();
            this._animationB = null;
        }
        this.slideB.style.cssText = "";
        this.slideB.style.display = "block";
    }

    abort() {
        if (this._delayTimeout) {
            clearTimeout(this._delayTimeout);
        }

        if (this._finishTimeout) {
            clearTimeout(this._finishTimeout);
        }

        this._finish();
    }
}


const homeSliderNode = document.querySelector(".home-slider");
if (homeSliderNode) {
    new HomeSlider(homeSliderNode);
}
